
import { Component, Vue } from 'vue-property-decorator'
import AreaSelect from '@/components/area-select.vue'
import { apiPackageAdd, apiPackageCategoryLists, apiPackageDetail, apiPackageEdit } from '@/api/goods'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        AreaSelect,
		MaterialSelect
    }
})
export default class AddPackage extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    form = {
        name: '',
		packageprice: '',
		is_baida: 0,
		image: '',
        category_id: '',
        sort: ''
    }

    rules = {
        name: [
            {
                required: true,
                message: '请输入套餐名称',
                trigger: ['blur', 'change']
            }
        ],
        category_id: [
            {
                required: true,
                message: '请选择套餐分类',
                trigger: ['blur', 'change']
            }
        ]
    }
    categoryLists = []
    handleSave() {
		if( this.form.is_baida==1 && this.form.packageprice=="" ){
			this.$message.success('百搭需要输入套餐价格')
			return
		}
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                const api = this.id ? apiPackageEdit(this.form) : apiPackageAdd(this.form)
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }
    getPackageCategory() {
        apiPackageCategoryLists({}).then((res: any) => {
            this.categoryLists = res?.lists
        })
    }

    getPackageDetail() {
        this.loading = true
        apiPackageDetail(this.id).then((res: any) => {
            this.form = res
            this.loading = false
        })
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getPackageDetail()
        this.getPackageCategory()
    }
}
